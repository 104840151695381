import styled from "styled-components";





export const SentScroll = styled.div`
min-height: 100%;
display: flex;
align-items: center;
`

export const SentText = styled.div`
font-size: 18px;
font-weight: 500;

@media (min-width:768px) {
   font-size: 24px;
}
@media (min-width:1100px) {
   font-size: 36px;
}
`

