import React from "react";
import { Container } from '../Global-Components/Container';
import * as H from './Header-style';
import Logo from './Header-components/Logo';
import Anhor from './Header-components/Anhor';
import Button from '../Button/Button';



export default function Header({ scrollAnhor, setContactModal }) {



   return (
      <header>
         <Container>
            <H.HeaderBody>
               <Logo />
               <H.Menu>
                  <Anhor scrollAnhor={scrollAnhor} />
                  <Button changeStateModal={setContactModal} text="let’s get started" nameClass="header-button" />
               </H.Menu>
            </H.HeaderBody>
         </Container>
      </header>
   )
}

