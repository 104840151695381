import styled from "styled-components";

export const Logo = styled.div`
margin: 130px 0 53px;

a{
   display: inline-block;

width: 750px;
max-width: 100%;
}
`