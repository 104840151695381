import React from "react";
import { useScramble } from "use-scramble";
import { motion } from "framer-motion";
import { FooterButton, FooterAnhorText } from './Footer-style';



const styleMask = {
   display: "block",
   position: "absolute",
   top: "0px",
   left: "0px",
   width: "100%",
   color: "inherit"
}



export default function Link({ text, scrollAnhor }) {
   const { ref, replay } = useScramble({
      text: text,
      step: 0.1,
      scramble: 10,
   });



   return (


      <FooterButton href="#top" onClick={() => { scrollAnhor("top") }}>
         <span>
            <FooterAnhorText>
               {text}
            </FooterAnhorText>

            <motion.span
               style={styleMask}
               ref={ref}
               onMouseOver={replay}
               onFocus={replay}
            />

         </span>
         <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.30654 0.291016L14.6131 7.48603L13.6591 8.4254L7.98106 2.83397V17.561H6.63201V2.83397L0.953924 8.4254L0 7.48603L7.30654 0.291016Z" fill="white" />
         </svg>
      </FooterButton>



















   )
}



/* 
<motion.a
         href={"#anhor" + props.props.id}
         onClick={() => { props.scrollAnhor("#anhor" + props.props.id) }}
      >
         <FooterAnhorText>
            {props.props.name}
         </FooterAnhorText>

         <motion.span
            style={styleMask}
            ref={ref}
            onMouseOver={replay}
            onFocus={replay}
         />

      </motion.a> */