import styled from 'styled-components';

export const Button = styled.button`

svg{
   width: 10vw;
   height: 10vw;
      @media (min-width:768px) {
      width: 127px;
      height: 127px;
   }
}
`