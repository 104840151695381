import { React, useRef } from "react";
import { ContainerLarge } from "../Global-Components/Container";
import * as S from "./Contact-style";
import Input from "./Input";
import InputMail from "./Input-mail";
import Textarea from "./Textarea";
import Button from "../Button/Button";
import ButtonClose from "../Button-Close/Button-Close";
import InputMedia from "./Input-Media";
import { BackgroundModal } from "../Global-Components/Background";
import { $api } from "../../api";

const animation = {
  visible: {
    top: 0,
    transition: "top 1s 0.1s cubic-bezier(.77,0,.175,1)",
  },
  hidden: {
    top: "-120vh",
    transition: "top 1s cubic-bezier(.77,0,.175,1)",
  },
};

const animationBackground = {
  visible: {
    top: 0,
    transition: "top 1s cubic-bezier(.77,0,.175,1)",
  },
  hidden: {
    top: "-120vh",
    transition: "top 1s 0.1s cubic-bezier(.77,0,.175,1)",
  },
};

const id = "contacts";

export default function Contact({ setContactModal, contactModal, setSentModal }) {
  const phoneInputValid = useRef(false);

  async function checkSubmit(event) {
    event.preventDefault();
    console.log("event:", event);
    if (!phoneInputValid.current) return;

    const email = document.getElementById("email")?.value ?? "";
    const name = document.getElementById("name")?.value ?? "";
    const media = document.getElementById("media")?.value ?? "";
    const link = document.getElementById("link")?.value ?? "";
    const project = document.getElementById("project")?.value ?? "";
    try {
      const formData = {
        name,
        description: project,
        email,
        contactType: media,
        contact: link,
        number: "",
      };

      const response = await $api.post("/feedback", formData, {
        headers: { "Project-Name": "paylect.com" },
      });

      console.log("API /feedback response", response.data);

      setSentModal(true);
      setContactModal(false);
    } catch (e) {
      console.error(e);
    }
  }

  function statePhoneInput(state) {
    state ? (phoneInputValid.current = true) : (phoneInputValid.current = false);
  }

  return (
    <>
      <BackgroundModal
        style={contactModal ? animationBackground.visible : animationBackground.hidden}
      />
      <S.ContactWrapper
        data-lenis-prevent
        id={id}
        style={contactModal ? animation.visible : animation.hidden}
      >
        <ContainerLarge>
          <form
            action="#"
            method="post"
            name=""
            id=""
            onSubmit={(event) => {
              checkSubmit(event);
            }}
          >
            <S.ContactBody>
              <S.ContactShell>
                <S.ContactTitle>
                  <ButtonClose changeStateModal={setContactModal} />
                  <span>Contact Us</span>
                  <S.Indent></S.Indent>
                </S.ContactTitle>
                <S.ContactText>
                  <S.ContactTextBlock>
                    <div>&#91; Disclaimer</div>
                    <div>
                      <span>Fill out the form to receive customized terms for your project</span>
                    </div>
                  </S.ContactTextBlock>
                </S.ContactText>
              </S.ContactShell>
              <div>
                <Input
                  type="text"
                  key="1"
                  name="name"
                  id="name"
                  placeholder="Enter your name *"
                  required
                  statePhoneInput={statePhoneInput}
                />

                <InputMail
                  type="text"
                  key="2"
                  name="email"
                  id="email"
                  placeholder="Enter your email *"
                  required
                  statePhoneInput={statePhoneInput}
                />

                <InputMedia statePhoneInput={statePhoneInput} />

                <Textarea
                  key="5"
                  name="project"
                  id="project"
                  placeholder="Describe your project *"
                  required
                  statePhoneInput={statePhoneInput}
                />

                <S.ContactTextForm>
                  <S.ContactText>
                    By clicking the "Send" button, you give your consent to the paylect
                    Administration to process and store your personal and other data provided in the
                    form above.
                  </S.ContactText>
                </S.ContactTextForm>
                <S.ContactButtonWrapper>
                  <Button type={"submit"} text="send" />
                </S.ContactButtonWrapper>
              </div>
            </S.ContactBody>
          </form>
        </ContainerLarge>
      </S.ContactWrapper>
    </>
  );
}
