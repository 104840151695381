import { React, useRef } from "react";
import { Container } from "../Global-Components/Container";
import * as S from "./Footer-style";
import Link from "./Link";
import Back from "./Back";
import { Button } from "./Button";

const listAnhor = [
  { id: 1, name: "Our mission" },
  { id: 2, name: "Optimal" },
  { id: 3, name: "Services" },
  { id: 5, name: "FAQs" },
];

export default function Footer({ scrollAnhor, setPrivacyModal, setTermsModal }) {
  const media = window.matchMedia("(min-width: 1200px)");

  const refParentPrivacy = useRef(null);
  const refPrivacy = useRef(null);
  const refTerms = useRef(null);

  window.addEventListener("resize", () => {
    if (media.matches) {
      refParentPrivacy.current.append(refPrivacy.current);
      return;
    }
    refTerms.current.before(refPrivacy.current);
  });

  return (
    <S.Footer>
      <Container>
        <S.FooterBody>
          <S.FooterWrapper>
            <S.FooterInfo>Innovate. Customize. Collaborate.</S.FooterInfo>
            <div>
              <S.FooterLink href="#0">support@paylect.com</S.FooterLink>
              <br />
              <S.FooterBottomText>
                Paylect Limited Liability Company
                <br />
                Сompany number 3254 LLC 2023
                <br />
                Kingstown, St. Vincent and the Grenadines
              </S.FooterBottomText>
            </div>
          </S.FooterWrapper>
          <S.FooterBottom>
            <S.FooterAnhorBlock>
              {listAnhor.map((i) => (
                <div key={i.id}>
                  <Link props={i} scrollAnhor={scrollAnhor} />
                </div>
              ))}
              <S.Slash key="10">&#47;&#47;</S.Slash>
              <S.FooterPrivacy ref={refParentPrivacy} key="11">
                <Button ref={refPrivacy} text="Privacy" changeStateModal={setPrivacyModal} />
              </S.FooterPrivacy>
            </S.FooterAnhorBlock>
            <S.FooterTerms>
              <Button ref={refTerms} text="Terms" changeStateModal={setTermsModal} />
            </S.FooterTerms>
            <S.FooterCopyright>
              <Back text="Back to top" scrollAnhor={scrollAnhor} />
              <div>©2024 Paylect.com</div>
            </S.FooterCopyright>
          </S.FooterBottom>
        </S.FooterBody>
      </Container>
    </S.Footer>
  );
}
