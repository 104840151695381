import { React, useRef } from "react";
import { Container } from "../Global-Components/Container";
import { Logo } from "./Logo-style";
import Lottie from "lottie-react";
import logoJSON from '../../json/logo_green.json';
import useHoverLottie from "../../Hooks/useHoverLottie";



export default function LogoFooter() {

   const logoRef = useRef(null);

   const hoverLottie = useHoverLottie(logoRef);

   return (
      <Container>
         <Logo>
            <a href="index.htm">
               <Lottie animationData={logoJSON}
                  lottieRef={logoRef}
                  loop={false}
                  autoplay={false}
                  onMouseEnter={() => { hoverLottie.play() }}
                  onMouseLeave={() => { hoverLottie.stop() }}
                  onComplete={() => hoverLottie.check()}
               />
            </a>
         </Logo>
      </Container>
   )
}

