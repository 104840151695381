import { motion } from "framer-motion"


export default function useCarousel(word, color) {

   const Text = (k) => {
      return (
         <span key={k}>{word}</span>
      )
   }

   const Svg = (k) => {
      return (
         <span key={k + 1000}>
            <svg width="103" height="224" viewBox="0 0 103 224" fill="none" xmlns="http://www.w3.org/2000/svg">
               <motion.path
                  d="M20.7989 83.3696C33.3657 107.361 33.1828 117.597 20.7532 141.359C20.6362 141.557 20.5883 141.788 20.617 142.016C20.6457 142.243 20.7494 142.455 20.9117 142.618C21.0741 142.78 21.2859 142.884 21.5136 142.912C21.7414 142.941 21.9723 142.893 22.1699 142.776C45.8867 130.301 56.4885 130.301 80.2055 142.776C80.403 142.893 80.6338 142.941 80.8617 142.912C81.0895 142.884 81.3012 142.78 81.4636 142.618C81.6259 142.455 81.7296 142.243 81.7583 142.016C81.7869 141.788 81.7391 141.557 81.6221 141.359C69.1924 117.597 69.1467 107.041 81.5763 83.3696C81.6933 83.172 81.7414 82.9412 81.7126 82.7134C81.6839 82.4856 81.5802 82.2738 81.4179 82.1114C81.2555 81.9491 81.0438 81.8454 80.8159 81.8167C80.5882 81.788 80.3573 81.8359 80.1597 81.9529C56.2143 94.5654 45.9324 94.3369 22.2155 81.9529C22.018 81.8359 21.7871 81.788 21.5594 81.8167C21.3316 81.8454 21.1198 81.9491 20.9574 82.1114C20.7951 82.2738 20.6914 82.4856 20.6627 82.7134C20.634 82.9412 20.6819 83.172 20.7989 83.3696Z"
                  fill={color}
                  animate={{ rotate: [0, -360] }}
                  transition={{
                     repeat: Infinity,
                     duration: 8,
                     ease: "linear"
                  }}
               />
               <rect y="219.145" width="102.58" height="4" fill={color} />
            </svg>
         </span>
      )
   }
   const content = [];
   const buildLine = () => {
      for (let i = 0; i < 10; i++) {
         content.push(Text(i));
         content.push(Svg(i));
      }
   }
   buildLine();

   return content;
}