import { React, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import Lenis from "lenis";
import "./index.css";
import { GlobalStyle } from "./Components/Global-Components/GlobalStyle";
import Header from "./Components/Header/Header";
import { FirstScreenWrapper } from "./Components/First-Screen/First-screen-style";
import FirstScreenTitle from "./Components/First-Screen/First-Screen-Title";
import LottieBlock from "./Components/First-Screen/First-Screen-Lottie";
import Carousel from "./Components/Carousel-1/Carousel";
import Advantages from "./Components/Advantages/Advantages";
import Solution from "./Components/Solution/Solution";
import Services from "./Components/Services/Services";
import Quote from "./Components/Quote/Quote";
import Questions from "./Components/Questions/Questions";
import LogoFooter from "./Components/Logo/Logo-footer";
import Carousel2 from "./Components/Carousel-2/Carousel";
import Footer from "./Components/Footer/Footer";
import Terms from "./Components/Terms/Terms";
import Contact from "./Components/Contact/Contact";
import Sent from "./Components/Sent/Sent";
import Privacy from "./Components/Privacy/Privacy";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <GlobalStyle />
    <App />
  </>
);

function App() {
  /* функция прокрутки по якорям */
  const scrollAnhor = (n) => {
    lenis.scrollTo(n);
  };

  /* плавная прокрутка Lenis */
  let documentHeight = document.body.scrollHeight;
  const lenis = new Lenis({
    duration: 1.9,
    autoResize: true,
  });
  function raf(time) {
    lenis.raf(time);
    if (documentHeight !== document.body.scrollHeight) {
      documentHeight = document.body.scrollHeight;
      lenis.resize();
    }
    requestAnimationFrame(raf);
  }
  useEffect(() => {
    requestAnimationFrame(raf);
  });

  /* отрывает модальные окна */
  const [contactModal, setContactModal] = useState(false);
  const [sentModal, setSentModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);
  const [termsModal, setTermsModal] = useState(false);

  useEffect(() => {
    if (contactModal || sentModal || termsModal) {
      document.documentElement.style.overflow = "hidden";
      return;
    }
    document.documentElement.style.overflow = "";
  }, [contactModal, sentModal, termsModal]);

  return (
    <>
      <FirstScreenWrapper>
        <Header scrollAnhor={scrollAnhor} setContactModal={setContactModal} />
        <LottieBlock />
        <FirstScreenTitle />
      </FirstScreenWrapper>

      <Carousel />

      <Advantages />

      <Solution setContactModal={setContactModal} />

      <Services setContactModal={setContactModal} />

      <Quote />

      <Questions />

      <LogoFooter />

      <Carousel2 />

      <Footer
        scrollAnhor={scrollAnhor}
        setTermsModal={setTermsModal}
        setPrivacyModal={setPrivacyModal}
      />

      <Privacy privacyModal={privacyModal} setPrivacyModal={setPrivacyModal} />
      <Terms termsModal={termsModal} setTermsModal={setTermsModal} />

      <Contact
        contactModal={contactModal}
        setContactModal={setContactModal}
        setSentModal={setSentModal}
      />

      <Sent setSentModal={setSentModal} sentModal={sentModal} />
    </>
  );
}
