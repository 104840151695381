import React from "react";
import { Container } from "../Global-Components/Container";
import * as C from './First-screen-style';

export default function FirstScreenTitle() {

   return (
      <section>
         <Container>
            <C.Title>Your gateway to seamless crypto payments for business.</C.Title>
            <C.Subtitle>Discover the future of payments with our cutting-edge cryptocurrency payment solution.</C.Subtitle>
            <C.Line />
            <C.FirstScreenHeader>
               <span>©2024:V.1.0</span>
               <C.Scroll>
                  Scroll
                  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M9.16962 21.6901L0.344238 12.9994L1.49646 11.8648L8.35488 18.6185V0.830078L9.98436 0.830078V18.6185L16.8427 11.8648L17.995 12.9994L9.16962 21.6901Z" fill="white" />
                  </svg>
               </C.Scroll>
            </C.FirstScreenHeader>
         </Container>
      </section>

   )
}



