import { React } from "react";
import { ContainerModal } from "../Global-Components/Container";
import * as T from '../Terms/Terms-style';
import ButtonClose from "../Button-Close/Button-Close";
import { BackgroundModal } from "../Global-Components/Background";
import * as S from './Sent-style';

const animation = {

   visible: {
      top: 0,
      transition: "top 1s 0.1s cubic-bezier(.77,0,.175,1)"
   },
   hidden: {
      top: "-120vh",
      transition: "top 1s cubic-bezier(.77,0,.175,1)"
   }
}


const animationBackground = {
   visible: {
      top: 0,
      transition: "top 1s cubic-bezier(.77,0,.175,1)"
   },
   hidden: {
      top: "-120vh",
      transition: "top 1s 0.1s cubic-bezier(.77,0,.175,1)"
   }
}

const id = "sent";

export default function Terms({ setSentModal, sentModal }) {

   function closeModal(event) {
      if (!event.target.closest('.modal-body')) { setSentModal(false) }
   }

   return (

      <>
         <BackgroundModal
            style={sentModal ? animationBackground.visible : animationBackground.hidden}
         />

         <T.TermsWrapper
            id={id}
            data-lenis-prevent
            style={sentModal ? animation.visible : animation.hidden}
            onClick={(event) => { closeModal(event) }}
         >

            <S.SentScroll>

               <ContainerModal>

                  <T.TermsBody className="modal-body">
                     <T.SentButtonClose>
                        <ButtonClose changeStateModal={setSentModal} />
                     </T.SentButtonClose>
                     <T.TermsHeader>
                        <T.TermsTitle>Сontact form</T.TermsTitle>
                        <T.HideButton>
                           <ButtonClose />
                        </T.HideButton>
                     </T.TermsHeader>
                     <S.SentText>YOUR MESSAGE HAS BEEN SENT SUCCESSFULLY!</S.SentText>

                  </T.TermsBody>

               </ContainerModal>

            </S.SentScroll>

         </T.TermsWrapper>
      </>


   )
}


/* 













 */


