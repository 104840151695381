import React from "react";
import * as C from './Block-style';


export default function BlockTitle(props) {

   return (
      <>
         <C.BlockHeader>
            <C.H2>{props.data.title}</C.H2>
            <C.P>{props.data.text}</C.P>
         </C.BlockHeader>
         <C.Line />
         <C.Icons>
            <span>
               <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.61403 16.8706C13.0055 16.8706 16.5655 13.3107 16.5655 8.9192C16.5655 4.52775 13.0055 0.967773 8.61403 0.967773C4.22257 0.967773 0.662598 4.52775 0.662598 8.9192C0.662598 13.3107 4.22257 16.8706 8.61403 16.8706Z" stroke="white" strokeWidth="1.32524" />
                  <path d="M19.2161 16.8706C23.6075 16.8706 27.1675 13.3107 27.1675 8.9192C27.1675 4.52775 23.6075 0.967773 19.2161 0.967773C14.8246 0.967773 11.2646 4.52775 11.2646 8.9192C11.2646 13.3107 14.8246 16.8706 19.2161 16.8706Z" stroke="white" strokeWidth="1.32524" />
               </svg>
            </span>
            <span>
               <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.96658 14.4386C10.7871 16.2576 12.3878 17.9164 14.1921 23.6787C14.205 23.7288 14.2341 23.7731 14.2749 23.805C14.3158 23.8367 14.366 23.8538 14.4177 23.8538C14.4694 23.8538 14.5196 23.8367 14.5605 23.805C14.6013 23.7731 14.6304 23.7288 14.6432 23.6787C16.4331 17.9164 18.121 16.2285 23.8833 14.4386C23.9334 14.4258 23.9777 14.3967 24.0096 14.3559C24.0413 14.315 24.0584 14.2648 24.0584 14.2131C24.0584 14.1614 24.0413 14.1112 24.0096 14.0704C23.9777 14.0295 23.9334 14.0004 23.8833 13.9875C18.121 12.1832 16.4331 10.5098 14.6432 4.76199C14.6304 4.71191 14.6013 4.66751 14.5605 4.63581C14.5196 4.60413 14.4694 4.58691 14.4177 4.58691C14.366 4.58691 14.3158 4.60413 14.2749 4.63581C14.2341 4.66751 14.205 4.71191 14.1921 4.76199C12.3878 10.5825 10.7144 12.1832 4.96658 13.9875C4.9165 14.0004 4.8721 14.0295 4.8404 14.0704C4.80872 14.1112 4.7915 14.1614 4.7915 14.2131C4.7915 14.2648 4.80872 14.315 4.8404 14.3559C4.8721 14.3967 4.9165 14.4258 4.96658 14.4386Z" fill="white" />
                  <path d="M14.4247 27.0647C21.5186 27.0647 27.2693 21.314 27.2693 14.2201C27.2693 7.12622 21.5186 1.37549 14.4247 1.37549C7.33081 1.37549 1.58008 7.12622 1.58008 14.2201C1.58008 21.314 7.33081 27.0647 14.4247 27.0647Z" stroke="white" strokeWidth="2.14077" />
               </svg>
            </span>
         </C.Icons>
      </>
   )
}