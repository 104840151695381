import React from "react";
import BlockTitle from "../Block-Title/Block-Title";
import { Container } from "../Global-Components/Container";
import * as S from "./Advantages-style";
import Card from "./Advantages-Card";

export default function Advantages() {
  const content = {
    title: "Our mission",
    text: "Empowering businesses with secure, fast, and versatile cryptocurrency payment solutions that meet every financial need.",
  };

  // const img_1 = (
  //   <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
  //     <path
  //       d="M44.2099 86.6088C67.7505 86.6088 86.8339 67.5254 86.8339 43.9848C86.8339 20.4443 67.7505 1.36084 44.2099 1.36084C20.6694 1.36084 1.58594 20.4443 1.58594 43.9848C1.58594 67.5254 20.6694 86.6088 44.2099 86.6088Z"
  //       stroke="#030303"
  //       strokeWidth="1.332"
  //     />
  //     <path
  //       d="M44.375 43.3188C55.9614 43.3188 65.354 33.9262 65.354 22.3398C65.354 10.7535 55.9614 1.36084 44.375 1.36084C32.7886 1.36084 23.396 10.7535 23.396 22.3398C23.396 33.9262 32.7886 43.3188 44.375 43.3188Z"
  //       stroke="#030303"
  //       strokeWidth="1.332"
  //     />
  //     <path
  //       d="M44.375 86.6089C55.9614 86.6089 65.354 77.2163 65.354 65.6299C65.354 54.0435 55.9614 44.6509 44.375 44.6509C32.7886 44.6509 23.396 54.0435 23.396 65.6299C23.396 77.2163 32.7886 86.6089 44.375 86.6089Z"
  //       stroke="#030303"
  //       strokeWidth="1.332"
  //     />
  //     <path d="M44.21 0.694824V87.2748" stroke="#030303" strokeWidth="1.332" />
  //     <path d="M87.4999 43.9849H0.919922" stroke="#030303" strokeWidth="1.332" />
  //     <path
  //       d="M44.2099 47.3148C46.049 47.3148 47.5399 45.8239 47.5399 43.9848C47.5399 42.1457 46.049 40.6548 44.2099 40.6548C42.3708 40.6548 40.8799 42.1457 40.8799 43.9848C40.8799 45.8239 42.3708 47.3148 44.2099 47.3148Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M75.5121 47.3148C76.9834 47.3148 78.1761 46.1221 78.1761 44.6508C78.1761 43.1795 76.9834 41.9868 75.5121 41.9868C74.0409 41.9868 72.8481 43.1795 72.8481 44.6508C72.8481 46.1221 74.0409 47.3148 75.5121 47.3148Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M22.3936 28.9901C23.434 30.0304 25.1207 30.0304 26.1611 28.9901C27.2014 27.9497 27.2014 26.263 26.1611 25.2226C25.1207 24.1822 23.434 24.1822 22.3936 25.2226C21.3533 26.263 21.3533 27.9497 22.3936 28.9901Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M77.1673 16.6118C78.2077 15.5714 78.2077 13.8847 77.1673 12.8443C76.127 11.8039 74.4402 11.8039 73.3998 12.8443C72.3595 13.8847 72.3595 15.5714 73.3998 16.6118C74.4402 17.6521 76.127 17.6521 77.1673 16.6118Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M15.5717 45.9828C17.043 45.9828 18.2357 44.7901 18.2357 43.3188C18.2357 41.8475 17.043 40.6548 15.5717 40.6548C14.1004 40.6548 12.9077 41.8475 12.9077 43.3188C12.9077 44.7901 14.1004 45.9828 15.5717 45.9828Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M26.5511 73.8881C27.5915 72.8478 27.5915 71.161 26.5511 70.1207C25.5107 69.0803 23.824 69.0803 22.7836 70.1207C21.7433 71.161 21.7433 72.8478 22.7836 73.8881C23.824 74.9285 25.5107 74.9285 26.5511 73.8881Z"
  //       fill="#FF6338"
  //     />
  //   </svg>
  // );

  // const img_2 = (
  //   <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
  //     <path
  //       d="M43.6816 86.291C67.0467 86.291 85.9878 67.3499 85.9878 43.9848C85.9878 20.6198 67.0467 1.67871 43.6816 1.67871C20.3166 1.67871 1.37549 20.6198 1.37549 43.9848C1.37549 67.3499 20.3166 86.291 43.6816 86.291Z"
  //       stroke="#030303"
  //       strokeWidth="1.31182"
  //     />
  //     <path
  //       d="M65.223 22.9209H22.3794V65.5259H65.223V22.9209Z"
  //       stroke="#030303"
  //       strokeWidth="1.31182"
  //     />
  //     <path d="M43.6816 1.02295V86.947" stroke="#030303" strokeWidth="1.31182" />
  //     <path d="M86.6438 43.9849H0.719727" stroke="#030303" strokeWidth="1.31182" />
  //     <path d="M74.0602 13.606L13.3027 74.3635" stroke="#030303" strokeWidth="1.31182" />
  //     <path d="M13.3032 13.606L74.0607 74.3635" stroke="#030303" strokeWidth="1.31182" />
  //     <path
  //       d="M43.6817 46.6086C45.1307 46.6086 46.3054 45.434 46.3054 43.985C46.3054 42.536 45.1307 41.3613 43.6817 41.3613C42.2327 41.3613 41.0581 42.536 41.0581 43.985C41.0581 45.434 42.2327 46.6086 43.6817 46.6086Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M64.671 46.6086C66.12 46.6086 67.2946 45.434 67.2946 43.985C67.2946 42.536 66.12 41.3613 64.671 41.3613C63.222 41.3613 62.0474 42.536 62.0474 43.985C62.0474 45.434 63.222 46.6086 64.671 46.6086Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M62.8157 66.6041C63.8403 67.6287 65.5015 67.6287 66.5261 66.6041C67.5507 65.5796 67.5507 63.9184 66.5261 62.8938C65.5015 61.8692 63.8403 61.8692 62.8157 62.8938C61.7911 63.9184 61.7911 65.5796 62.8157 66.6041Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M66.3009 24.6257C67.3255 23.6011 67.3255 21.9399 66.3009 20.9153C65.2763 19.8907 63.6151 19.8907 62.5905 20.9153C61.5659 21.9399 61.5659 23.6011 62.5905 24.6257C63.6151 25.6503 65.2763 25.6503 66.3009 24.6257Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M22.6925 46.6086C24.1415 46.6086 25.3161 45.434 25.3161 43.985C25.3161 42.536 24.1415 41.3613 22.6925 41.3613C21.2435 41.3613 20.0688 42.536 20.0688 43.985C20.0688 45.434 21.2435 46.6086 22.6925 46.6086Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M20.8377 24.6256C21.8623 25.6502 23.5235 25.6502 24.5481 24.6256C25.5727 23.601 25.5727 21.9398 24.5481 20.9152C23.5235 19.8907 21.8623 19.8907 20.8377 20.9152C19.8131 21.9398 19.8131 23.601 20.8377 24.6256Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M24.3229 66.6042C25.3475 65.5796 25.3475 63.9184 24.3229 62.8938C23.2983 61.8692 21.6371 61.8692 20.6125 62.8938C19.5879 63.9184 19.5879 65.5796 20.6125 66.6042C21.6371 67.6288 23.2983 67.6288 24.3229 66.6042Z"
  //       fill="#FF6338"
  //     />
  //   </svg>
  // );

  // const img_3 = (
  //   <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
  //     <path
  //       d="M43.5717 85.4212C66.588 85.4212 85.2464 66.7628 85.2464 43.7465C85.2464 20.7302 66.588 2.07178 43.5717 2.07178C20.5554 2.07178 1.89697 20.7302 1.89697 43.7465C1.89697 66.7628 20.5554 85.4212 43.5717 85.4212Z"
  //       stroke="#030303"
  //       strokeWidth="1.29224"
  //     />
  //     <path
  //       d="M14.5684 73.2337L14.5534 73.2414L14.5389 73.2501C14.4669 73.2927 14.3828 73.3102 14.2999 73.2997C14.2169 73.2892 14.1397 73.2515 14.0806 73.1923C14.0214 73.1331 13.9836 73.056 13.9732 72.973C13.9627 72.8901 13.9802 72.8059 14.0228 72.734L14.0316 72.7191L14.0396 72.7037C20.1737 60.9562 23.3564 52.4325 23.3852 43.8333C23.414 35.2327 20.2877 26.6513 14.0386 14.7871L14.0311 14.7728L14.0228 14.759C13.9802 14.687 13.9627 14.6028 13.9732 14.5199C13.9836 14.4368 14.0214 14.3597 14.0806 14.3006C14.1397 14.2414 14.2169 14.2037 14.2999 14.1932C14.3828 14.1827 14.4669 14.2002 14.5389 14.2428L14.5531 14.2512L14.5679 14.259C26.2973 20.4181 34.8773 23.5432 43.516 23.5317C52.1538 23.5202 60.756 20.3726 72.5294 14.2143L72.5445 14.2063L72.5592 14.1976C72.6311 14.155 72.7152 14.1375 72.7982 14.148C72.8812 14.1584 72.9583 14.1962 73.0175 14.2554C73.0767 14.3146 73.1144 14.3917 73.1249 14.4747C73.1354 14.5576 73.1179 14.6417 73.0753 14.7137L73.0669 14.7277L73.0594 14.7422C66.8778 26.4938 63.7299 35.0965 63.7299 43.7465C63.7299 52.3963 66.8778 60.999 73.0594 72.7506L73.0669 72.7651L73.0753 72.7792C73.1179 72.8512 73.1354 72.9353 73.1249 73.0182C73.1144 73.1012 73.0767 73.1784 73.0175 73.2375C72.9583 73.2966 72.8812 73.3345 72.7982 73.3449C72.7152 73.3554 72.6311 73.3378 72.5592 73.2953L72.5445 73.2865L72.5294 73.2786C60.7592 67.1219 52.2352 63.9391 43.636 63.9103C35.0355 63.8815 26.4544 67.0077 14.5684 73.2337Z"
  //       stroke="#030303"
  //       strokeWidth="1.29224"
  //     />
  //     <path
  //       d="M43.9039 14.0698L14.061 43.9126L43.7376 73.5892L73.5805 43.7464L43.9039 14.0698Z"
  //       stroke="#030303"
  //       strokeWidth="1.29224"
  //     />
  //     <path d="M13.646 13.8208L73.4967 73.6714" stroke="#030303" strokeWidth="1.29224" />
  //     <path d="M73.4972 13.8208L13.6465 73.6714" stroke="#030303" strokeWidth="1.29224" />
  //     <path
  //       d="M43.8102 47.2151C45.5944 47.2151 47.0408 45.7687 47.0408 43.9845C47.0408 42.2003 45.5944 40.7539 43.8102 40.7539C42.026 40.7539 40.5796 42.2003 40.5796 43.9845C40.5796 45.7687 42.026 47.2151 43.8102 47.2151Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M65.1318 54.9688C66.5592 54.9688 67.7163 53.8116 67.7163 52.3843C67.7163 50.9569 66.5592 49.7998 65.1318 49.7998C63.7045 49.7998 62.5474 50.9569 62.5474 52.3843C62.5474 53.8116 63.7045 54.9688 65.1318 54.9688Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M53.8155 24.2684C54.8248 23.2591 54.8248 21.6227 53.8155 20.6134C52.8062 19.6041 51.1698 19.6041 50.1605 20.6134C49.1512 21.6227 49.1512 23.2591 50.1605 24.2684C51.1698 25.2777 52.8062 25.2777 53.8155 24.2684Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M28.9492 61.4297C30.3766 61.4297 31.5337 60.2726 31.5337 58.8452C31.5337 57.4179 30.3766 56.2607 28.9492 56.2607C27.5219 56.2607 26.3647 57.4179 26.3647 58.8452C26.3647 60.2726 27.5219 61.4297 28.9492 61.4297Z"
  //       fill="#FF6338"
  //     />
  //     <path
  //       d="M26.9 30.7301C27.9093 31.7394 29.5457 31.7394 30.555 30.7301C31.5643 29.7208 31.5643 28.0844 30.555 27.0751C29.5457 26.0658 27.9093 26.0658 26.9 27.0751C25.8907 28.0844 25.8907 29.7208 26.9 30.7301Z"
  //       fill="#FF6338"
  //     />
  //   </svg>
  // );

  const list = [
    {
      id: 1,
      header: ["Main advantages", "Global Reach"],
      image: "",
      json: "global_reach.json",
      title: "Global Reach",
      text: "Accept crypto payments from anywhere in the world, breaking down geographic barriers.",
    },
    {
      id: 2,
      header: ["Main advantages", "Easy Integration"],
      image: "",
      json: "easy_integration.json",
      title: "Easy Integration",
      text: "Quickly and seamlessly integrate Paylect into your existing systems without extensive technical expertise.",
    },
    {
      id: 3,
      header: ["Main advantages", "Versatile Tools"],
      image: "",
      json: "versatile_tools.json",
      title: "Versatile Tools",
      text: "All-in-one tools designed to simplify and enhance your cryptocurrency financial management.",
    },
  ];

  return (
    <Container id="anhor1">
      <section>
        <BlockTitle data={content} />

        <S.CardGrid>
          {list.map((item) => (
            <Card data={item} key={item.id} />
          ))}
        </S.CardGrid>
      </section>
    </Container>
  );
}
