import React from "react";
import * as C from '../Header-style';
import AnhorText from './Anhor-text';

const listAnhor = [
   { id: 1, name: 'Our mission' },
   { id: 2, name: 'Optimal' },
   { id: 3, name: 'Services' },
   { id: 5, name: 'FAQs' }
]



export default function Anhor({ scrollAnhor }) {

   return (
      <C.AnhorBody>
         <C.AnhorList>
            {listAnhor.map((item) =>
               <li key={item.id}>
                  <AnhorText item={item} scrollAnhor={scrollAnhor} />
               </li>
            )}
         </C.AnhorList>
      </C.AnhorBody >
   )
}