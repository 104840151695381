import { React, useRef } from "react";
import * as S from './Button-style';
import { useScramble } from "use-scramble";
import { motion, useInView } from "framer-motion";


const styleLineH = {
   display: "block",
   position: "absolute",
   left: "50%",
   zIndex: 1,
   transform: "translateX(-50%)",
   height: "1px",
   backgroundColor: "var(--color-white-solid)",
}
const styleLineTopV = {
   display: "block",
   position: "absolute",
   zIndex: 1,
   top: 0,
   transform: "translateX(-50%)",
   width: "1px",
   backgroundColor: "var(--color-white-solid)",
}
const styleLineBottomV = {
   display: "block",
   position: "absolute",
   zIndex: 1,
   bottom: 0,
   transform: "translateX(-50%) rotate(180deg)",
   width: "1px",
   backgroundColor: "var(--color-white-solid)",
}




export default function Button({ changeStateModal, text, type, nameClass }) {

   const media768 = window.matchMedia('(min-width: 768px)');

   const { ref, replay } = useScramble({
      text: text,
      step: 0.1,
      scramble: 10,
   });

   const variantsH = {
      hide: {
         width: 0,
         transition: {
            duration: 0.5,
            delay: 0.5
         }
      },
      show: {
         width: "100%",
         transition: {
            duration: 0.5
         }
      }
   }
   const variantsV = {
      hide: {
         height: 0,
         transition: {
            duration: 0.5
         }
      },
      show: {
         height: "18%",
         transition: {
            duration: 0.5,
            delay: 0.5
         }
      }
   }

   const hoverButton = {
      hover: {},
      static: {}
   }

   const hoverButtonArrow = {
      hover: {
         rotate: 45,
         transition: { duration: 0.3 }
      },
      static: {}

   }

   const trigger = useRef(null);
   const isInView = useInView(trigger, { amount: 1, once: !media768.matches });


   return (

      <S.Button className={nameClass}>
         <motion.button
            ref={trigger}
            type={type ? type : "button"}
            onClick={() => { if (changeStateModal) changeStateModal(true) }}

            initial="static"
            whileHover="hover"
            variants={hoverButton}
         >

            <motion.span
               style={{ ...styleLineH, top: 0 }}
               variants={variantsH}
               initial="hide"
               animate={isInView ? "show" : "hide"}
            />
            <motion.span
               style={{ ...styleLineH, bottom: 0 }}
               variants={variantsH}
               initial="hide"
               animate={isInView ? "show" : "hide"}
            />


            <motion.span
               style={{ ...styleLineTopV, left: 0 }}
               variants={variantsV}
               initial="hide"
               animate={isInView ? "show" : "hide"}
            />
            <motion.span
               style={{ ...styleLineTopV, right: 0 }}
               variants={variantsV}
               initial="hide"
               animate={isInView ? "show" : "hide"}
            />
            <motion.span
               style={{ ...styleLineBottomV, left: 0 }}
               variants={variantsV}
               initial="hide"
               animate={isInView ? "show" : "hide"}
            />
            <motion.span
               style={{ ...styleLineBottomV, right: 0 }}
               variants={variantsV}
               initial="hide"
               animate={isInView ? "show" : "hide"}
            />

            <S.ButtonTextWrapper>
               <S.ButtonText
                  ref={ref}
                  onMouseOver={replay}
                  onFocus={replay}
               />
            </S.ButtonTextWrapper>

            <S.ButtonArrowWrapper>

               <motion.span
                  style={{ ...styleLineTopV, left: 0 }}
                  variants={variantsV}
                  initial="hide"
                  animate={isInView ? "show" : "hide"}
               />

               <motion.span
                  style={{ ...styleLineBottomV, left: 0 }}
                  variants={variantsV}
                  initial="hide"
                  animate={isInView ? "show" : "hide"}
               />

               <S.ButtonArrow>
                  <motion.svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                     variants={hoverButtonArrow}
                  >
                     <path d="M0.659668 9.74023L9.15967 1.24023M9.15967 1.24023H1.15967M9.15967 1.24023V9.74023" stroke="white" strokeWidth="1.5" />
                  </motion.svg>
               </S.ButtonArrow>
            </S.ButtonArrowWrapper>
         </motion.button>
      </S.Button >

   )
}